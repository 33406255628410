import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

import imgHero from "../assets/image/conference/png/hero-main.png";
import imgC1 from "../assets/image/conference/jpg/content-1-img.jpg";
import imgCR from "../assets/image/conference/png/content-1-round.png";
import Content4 from "../sections/landing6/Content3";

const Hero = () => {
  return (
    <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
      {/* <!-- Hero Area --> */}
      <div className="bg-default-6 mt-28 position-relative">
        <div
          className="hero-area bg-image bg-blue  dark-mode-texts mx-6 mx-lg-9 rounded-15"
          css={`
            background-image: url(https://shadepro-gatsby.netlify.app/static/pattern-4-8263160699f4d9a09419f6536a57e66a.png);
          `}
        >
          <Container>
            <Row className="justify-content-sm-end">
              <Col lg="6" md="8">
                <div className="hero-content pe-xl-16 pt-18 pt-lg-31 pb-md-18 pb-lg-30 mb-9 mb-md-0">
                  <h1 className="title gr-text-2 mb-8 heading-color">Application &amp; Data Integration</h1>
                  <p className="gr-text-8 mb-0 text-color-opacity">
                  We specialize in enabling seamless connectivity and interoperability across your IT landscape
                  through robust application and data integration strategies.
                  </p>
                  <Button href="/contact" variant="success" className=" mt-11 btn btn-success">
                    Contact us
                  </Button>
                </div>
              </Col>
              <Col
                lg="5"
                md="4"
                sm="7"
                className="offset-lg-1 align-self-sm-end position-relative">
                <div
                  className="hero-img hero-img-custom z-index-1 mb-n12 mb-md-0"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  data-aos-delay="500"
              >
                </div>
              </Col>
            </Row>
          </Container>
        </div>
          </div>
        <div className="content-section pt-13 pt-lg-29 pb-12 pb-lg-20 bg-default-6">
        <Container>
          <Row className="align-items-center ">
            <Col className="ps-lg-15">
              <div className="section-title content-text mb-13 mb-lg-15">
                <h2 className="title gr-text-4 mb-6 heading-color">Our Core Offerings</h2>
                <p className="gr-text-8 pe-lg-12 mb-0 text-color-opacity">
                Our tailored solutions empower organizations to unlock the full potential of their systems,
                streamline workflows, and drive business growth.
                </p>
              </div>
              <div className="content-widget">             
                <Row className="mb-n11">
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="750"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        1. API Integration
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Build and manage APIs to enable seamless communication between diverse
                      applications and systems. We design RESTful and GraphQL APIs that facilitate data
                      exchange and interoperability across your ecosystem.
                         </p>
                    </div>
                  </Col>
                  <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        2. Data Synchronization and Replication
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Ensure consistency and accuracy of data across multiple systems with automated data
                      synchronization and replication. Our solutions enable real-time or batch data replication
                      to keep your systems up-to-date and in sync.
                      </p>
                    </div>
                </Col>
                <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        3. Enterprise Service Bus (ESB) Implementation
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Implement an ESB to facilitate message-based communication between applications
                      and services. Our ESB solutions streamline integration by providing a centralized hub for
                      routing, transformation, and orchestration of messages.
                      </p>
                    </div>
                  </Col>
                <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        4. Cloud Integration
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Integrate cloud-based applications and services with on-premises systems or other
                      cloud platforms. We specialize in cloud-to-cloud and hybrid integrations using industry-
                      </p>
                    </div>
                  </Col>
                 <Col
                    md="6"
                    lg="10"
                    data-aos="fade-left"
                    data-aos-duration="1100"
                  >
                    <div className="single-widget mb-11">
                      <h3 className="w-title gr-text-7 mb-4 heading-color">
                        5. Event-Driven Architecture (EDA)
                      </h3>
                      <p className="gr-text-9 mb-0 text-color-opacity">
                      Implement event-driven integrations to enable real-time data processing and event-
                      driven workflows. We leverage technologies like Apache Kafka or AWS EventBridge to
                      build scalable and responsive architectures
                      </p>
                    </div>
                  </Col>               
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
    </div>
    <Content4 />
    </PageWrapper>
  );
};

export default Hero;